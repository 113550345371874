import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import axios from "axios";
import { server, config } from "../env";
import Loader from "../components/Loader";
import Alert from "../components/Alert";
import { Link } from "react-router-dom";

const Ftid = (props) => {
  const [ftids, setFtids] = useState([]);
  const [loader, setLoader] = useState("");
  const [message, setMessage] = useState("");
  const [ftid, setFtid] = useState({});
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    getFtids();
    readOrders();
  }, []);

  const addFtid = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);

    const data = {
      name: e.target.name.value,
      price: e.target.price.value,
    };

    await axios
      .post(server + "/api/v1/ftid/create", data, config)
      .then((res) => {
        setLoader("");
        setMessage(<Alert className="success" message={res.data.message} />);
      })
      .catch((err) => {
        setLoader("");
        setMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
      });

    setTimeout(() => {
      setMessage("");
      e.target.reset();
    }, 3000);
  };

  const getFtids = async () => {
    await axios.get(server + "/api/v1/ftid/read", config).then((res) => {
      setFtids(res.data.ftid);
    });
  };

  const updateFtid = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);

    const data = {
      name: ftid.name,
      price: ftid.price,
    };

    await axios
      .put(server + "/api/v1/ftid/update/" + ftid._id, data, config)
      .then((res) => {
        setLoader("");
        setMessage(<Alert className="success" message={res.data.message} />);
        getFtids();
      })
      .catch((err) => {
        setLoader("");
        setMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
      });

    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  const readOrders = async () => {
    await axios
      .get(server + "/api/v1/admin/ftidorders/read", config)
      .then((res) => {
        setOrders(res.data.orders);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="nk-wrap">
      <Header sidebarRef={props.sidebarRef} />
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="row mb-3">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header d-flex justify-content-between">
                      <h3 className="card-title">FTID Pricing</h3>
                      <button
                        className="btn btn-primary py-1 px-2"
                        data-toggle="modal"
                        data-target="#create"
                      >
                        Add FTID
                      </button>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Name</th>
                              <th>Price</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ftids.map((ftid, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{ftid.name}</td>
                                <td>${ftid.price}</td>
                                <td>
                                  <button
                                    className="btn btn-warning btn-sm"
                                    data-toggle="modal"
                                    data-target="#edit"
                                    onClick={() => setFtid(ftid)}
                                  >
                                    <em className="icon ni ni-edit" />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header d-flex justify-content-start">
                      <h3 className="card-title">FTID Orders</h3>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>email</th>
                              <th>FTID</th>
                              <th>price</th>
                              <th>date</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {orders.map((order, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{order.user.email}</td>
                                <td>{order.ftid.name}</td>
                                <td>${order.ftid.price}</td>
                                <td>
                                  {new Date(order.createdAt).toDateString()}
                                </td>
                                <td>
                                  <a
                                    className="btn btn-primary"
                                    href={server + "/" + order.pdf}
                                    download
                                  >
                                    {" "}
                                    Download PDF{" "}
                                    <em className="icon ni ni-download" />
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="create"
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Add FTID Pricing
              </h5>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              {message}
              <form onSubmit={addFtid}>
                <div className="form-group mb-3">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label>price</label>
                  <input
                    type="Number"
                    className="form-control"
                    name="price"
                    required
                  />
                </div>

                <div className="d-flex mt-2 justify-content-end ">
                  <button
                    type="button"
                    className="btn btn-white me-3"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Add {loader}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        id="edit"
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Update FTID Pricing
              </h5>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              {message}
              <form onSubmit={updateFtid}>
                <div className="form-group mb-3">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={ftid.name}
                    onChange={(e) => setFtid({ ...ftid, name: e.target.value })}
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label>price</label>
                  <input
                    type="Number"
                    className="form-control"
                    name="price"
                    value={ftid.price}
                    onChange={(e) =>
                      setFtid({ ...ftid, price: e.target.value })
                    }
                    required
                  />
                </div>

                <div className="d-flex mt-2 justify-content-end ">
                  <button
                    type="button"
                    className="btn btn-white me-3"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Update {loader}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* edit modal */}
    </div>
  );
};

export default Ftid;
