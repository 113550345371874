import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import axios from "axios";
import { server, config } from "../env";
import { BarChart } from "../tools/Chart";

const Dashboard = (props) => {
  const [OrderStats, setOrderStats] = useState({});
  const [revenueStats, setRevenueStats] = useState({});
  const [usersStats, setUsersStats] = useState({});
  const [depositStats, setDepositStats] = useState({});

  // dashboard stats
  const getOrdersStat = async () => {
    await axios
      .get(server + "/api/v1/dashboard/orders-stats", config)
      .then((res) => {
        setOrderStats(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getRevenueStats = async () => {
    await axios
      .get(server + "/api/v1/dashboard/revenue-stats", config)
      .then((res) => {
        setRevenueStats(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUsersStats = async () => {
    await axios
      .get(server + "/api/v1/dashboard/users-stats", config)
      .then((res) => {
        setUsersStats(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getDepositStats = async () => {
    await axios
      .get(server + "/api/v1/dashboard/deposit-stats", config)
      .then((res) => {
        setDepositStats(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // barchart data
  const getOrdersBarChart = async () => {
    await axios
      .get(server + "/api/v1/dashboard/read-orders-chart", config)
      .then((res) => {
        const e = document.getElementById("order_chart");
        var canvas = document.createElement("CANVAS");
        while (e.firstChild) {
          e.removeChild(e.firstChild);
        }
        e.appendChild(canvas);
        BarChart(canvas, res.data, "blue", "Orders");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // call all the functions at once
  const callAll = async () => {
    // promise all
    await Promise.all([
      getOrdersStat(),
      getRevenueStats(),
      getUsersStats(),
      getDepositStats(),
      getOrdersBarChart(),
    ]);
  };

  useEffect(() => {
    callAll();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="nk-wrap">
      <Header sidebarRef={props.sidebarRef} />
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">Dashboard</h3>
                  </div>
                  {/* <div className="d-flex">
                    <div className="form-group mr-2">
                      <label htmlFor="">From Date</label>
                      <input
                        type="date"
                        placeholder="From Date"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="">To Date</label>
                      <input
                        type="date"
                        placeholder="From Date"
                        className="form-control"
                      />
                    </div>
                  </div> */}
                </div>
              </div>

              <div className="nk-block">
                <div className="row g-gs">
                  <div className="col-md-3 col-sm-6">
                    <div className="card">
                      <div className="nk-ecwg nk-ecwg6">
                        <div className="card-inner">
                          <div className="card-title-group">
                            <div className="card-title">
                              <h5 className="title text-center">Orders</h5>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="data border-bottom">
                            {/* heading */}
                            <div className="data-head">
                              <h6 className="overline-title">Today</h6>
                            </div>
                            <div className="amount">
                              {OrderStats.todayOrdersCount
                                ? OrderStats.todayOrdersCount
                                : 0}
                            </div>
                          </div>
                          <div className="data border-bottom">
                            {/* heading */}
                            <div className="data-head">
                              <h6 className="overline-title">Week</h6>
                            </div>
                            <div className="amount">
                              {OrderStats.thisWeekOrdersCount
                                ? OrderStats.thisWeekOrdersCount
                                : 0}
                            </div>
                          </div>{" "}
                          <div className="data border-bottom">
                            {/* heading */}
                            <div className="data-head">
                              <h6 className="overline-title">Month</h6>
                            </div>
                            <div className="amount">
                              {OrderStats.thisMonthOrdersCount
                                ? OrderStats.thisMonthOrdersCount
                                : 0}
                            </div>
                          </div>
                          <div className="data border-bottom">
                            {/* heading */}
                            <div className="data-head">
                              <h6 className="overline-title">AllTime</h6>
                            </div>
                            <div className="amount">
                              {OrderStats.allTimeOrdersCount
                                ? OrderStats.allTimeOrdersCount
                                : 0}
                            </div>
                          </div>
                          {/* <div className="data">
                            <div className="data-head">
                              <h6 className="overline-title">Custom</h6>
                            </div>
                            <div className="amount">
                              {OrderStats.allTimeOrdersCount
                                ? OrderStats.allTimeOrdersCount
                                : 0}
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3 col-sm-6">
                    <div className="card">
                      <div className="nk-ecwg nk-ecwg6">
                        <div className="card-inner">
                          <div className="card-title-group">
                            <div className="card-title">
                              <h5 className="title text-center">Revenue</h5>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="data border-bottom">
                            {/* heading */}
                            <div className="data-head">
                              <h6 className="overline-title">Today</h6>
                            </div>
                            <div className="amount">
                              $
                              {revenueStats.todayRevenue
                                ? revenueStats.todayRevenue.toFixed(2)
                                : 0}
                            </div>
                          </div>
                          <div className="data border-bottom">
                            {/* heading */}
                            <div className="data-head">
                              <h6 className="overline-title">Week</h6>
                            </div>
                            <div className="amount">
                              $
                              {revenueStats.thisWeekRevenue
                                ? revenueStats.thisWeekRevenue.toFixed(2)
                                : 0}
                            </div>
                          </div>{" "}
                          <div className="data border-bottom">
                            {/* heading */}
                            <div className="data-head">
                              <h6 className="overline-title">Month</h6>
                            </div>
                            <div className="amount">
                              $
                              {revenueStats.thisMonthRevenue
                                ? revenueStats.thisMonthRevenue.toFixed(2)
                                : 0}
                            </div>
                          </div>
                          <div className="data border-bottom">
                            {/* heading */}
                            <div className="data-head">
                              <h6 className="overline-title">AllTime</h6>
                            </div>
                            <div className="amount">
                              $
                              {revenueStats.allTimeRevenue
                                ? revenueStats.allTimeRevenue.toFixed(2)
                                : 0}
                            </div>
                          </div>
                          {/* <div className="data">
                            <div className="data-head">
                              <h6 className="overline-title">Custom</h6>
                            </div>
                            <div className="amount">
                              $
                              {revenueStats.allTimeRevenue
                                ? revenueStats.allTimeRevenue.toFixed(2)
                                : 0}
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3 col-sm-6">
                    <div className="card">
                      <div className="nk-ecwg nk-ecwg6">
                        <div className="card-inner">
                          <div className="card-title-group">
                            <div className="card-title">
                              <h5 className="title text-center">Users</h5>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="data border-bottom">
                            {/* heading */}
                            <div className="data-head">
                              <h6 className="overline-title">Today</h6>
                            </div>
                            <div className="amount">
                              {usersStats.todayUsersCount
                                ? usersStats.todayUsersCount
                                : 0}
                            </div>
                          </div>
                          <div className="data border-bottom">
                            {/* heading */}
                            <div className="data-head">
                              <h6 className="overline-title">Week</h6>
                            </div>
                            <div className="amount">
                              {usersStats.thisWeekUsersCount
                                ? usersStats.thisWeekUsersCount
                                : 0}
                            </div>
                          </div>{" "}
                          <div className="data border-bottom">
                            {/* heading */}
                            <div className="data-head">
                              <h6 className="overline-title">Month</h6>
                            </div>
                            <div className="amount">
                              {usersStats.thisMonthUsersCount
                                ? usersStats.thisMonthUsersCount
                                : 0}
                            </div>
                          </div>
                          <div className="data border-bottom">
                            {/* heading */}
                            <div className="data-head">
                              <h6 className="overline-title">AllTime</h6>
                            </div>
                            <div className="amount">
                              {usersStats.allTimeUsersCount
                                ? usersStats.allTimeUsersCount
                                : 0}
                            </div>
                          </div>
                          {/* <div className="data ">
                            <div className="data-head">
                              <h6 className="overline-title">Custom</h6>
                            </div>
                            <div className="amount">
                              {usersStats.allTimeUsersCount
                                ? usersStats.allTimeUsersCount
                                : 0}
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <div className="card">
                      <div className="nk-ecwg nk-ecwg6">
                        <div className="card-inner">
                          <div className="card-title-group">
                            <div className="card-title">
                              <h5 className="title text-center">Deposits</h5>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="data border-bottom">
                            {/* heading */}
                            <div className="data-head">
                              <h6 className="overline-title">Today</h6>
                            </div>
                            <div className="amount">
                              $
                              {depositStats.todayInvoicesAmount
                                ? depositStats.todayInvoicesAmount.todayInvoicesAmount.toFixed(
                                    2
                                  )
                                : 0}
                            </div>
                          </div>
                          <div className="data border-bottom">
                            {/* heading */}
                            <div className="data-head">
                              <h6 className="overline-title">Week</h6>
                            </div>
                            <div className="amount">
                              $
                              {depositStats.thisWeekInvoicesAmount
                                ? depositStats.thisWeekInvoicesAmount.thisWeekInvoicesAmount.toFixed(
                                    2
                                  )
                                : 0}
                            </div>
                          </div>{" "}
                          <div className="data border-bottom">
                            {/* heading */}
                            <div className="data-head">
                              <h6 className="overline-title">Month</h6>
                            </div>
                            <div className="amount">
                              $
                              {depositStats.thisMonthInvoicesAmount
                                ? depositStats.thisMonthInvoicesAmount.thisMonthInvoicesAmount.toFixed(
                                    2
                                  )
                                : 0}
                            </div>
                          </div>
                          <div className="data border-bottom">
                            {/* heading */}
                            <div className="data-head">
                              <h6 className="overline-title">AllTime</h6>
                            </div>
                            <div className="amount">
                              $
                              {depositStats.allTimeInvoicesAmount
                                ? depositStats.allTimeInvoicesAmount?.allTimeInvoicesAmount?.toFixed(
                                    2
                                  )
                                : 0}
                            </div>
                          </div>
                          {/* <div className="data ">
                            <div className="data-head">
                              <h6 className="overline-title">Custom</h6>
                            </div>
                            <div className="amount">
                              $
                              {depositStats.allTimeInvoicesAmount
                                ? depositStats.allTimeInvoicesAmount.allTimeInvoicesAmount.toFixed(
                                    2
                                  )
                                : 0}
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="nk-block">
                <div className="row g-gs">
                  <div className="col-xxl-6">
                    <div className="card card-bordered h-100">
                      <div className="card-header">
                        <h5 className="card-title">Orders Graph</h5>
                      </div>
                      <div className="card-body">
                        <div id="order_chart"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
