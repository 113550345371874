import React, { useState } from "react";
import Header from "../components/Header";
import axios from "axios";
import { server, config } from "../env";
import { useEffect } from "react";
import Loader from "../components/Loader";
import Alert from "../components/Alert";
import { Link } from "react-router-dom";
const Faq = (props) => {
  const [faqs, setFaqs] = useState([]);
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState("");
  const [faq, setFaq] = useState({});

  const createFaq = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);
    const data = {
      question: e.target.question.value,
      answer: e.target.answer.value,
    };
    await axios
      .post(server + "/api/v1/faq/create", data, config)
      .then((res) => {
        setMessage(<Alert className="success" message={res.data.message} />);
        setLoader("");
        readFaq();
      })
      .catch((err) => {
        console.log(err);
        setMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
        setLoader("");
      });

    setTimeout(() => {
      setMessage("");
      e.target.reset();
    }, 3000);
  };

  const updateFaq = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);
    const data = {
      question: faq.question,
      answer: faq.answer,
    };
    await axios
      .put(server + "/api/v1/faq/update/" + faq._id, data, config)
      .then((res) => {
        setMessage(<Alert className="success" message={res.data.message} />);
        setLoader("");
        readFaq();
      })
      .catch((err) => {
        console.log(err);
        setMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
      });

    setTimeout(() => {
      setMessage("");
      e.target.reset();
    }, 3000);
  };

  const deleteFaq = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);
    await axios
      .delete(server + "/api/v1/faq/delete/" + faq._id, config)
      .then((res) => {
        setMessage(<Alert className="success" message={res.data.message} />);
        setLoader("");
        readFaq();
      })
      .catch((err) => {
        console.log(err);
        setMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
      });

    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  const readFaq = async () => {
    await axios
      .get(server + "/api/v1/faq/read", config)
      .then((res) => {
        setFaqs(res.data.data);
        setLoader("");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    readFaq();
  }, []);
  return (
    <div className="nk-wrap">
      {/* main header @s */}
      <Header sidebarRef={props.sidebarRef} />
      {/* main header @e */}
      {/* content @s */}
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between"></div>
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="d-flex justify-content-between">
                          <div className="nk-block-head-content">
                            <h3 className="nk-block-title page-title">
                              Frequently Asked Questions
                            </h3>
                          </div>
                          <button
                            className="btn btn-sm btn-primary px-2 py-1"
                            type="button"
                            data-toggle="modal"
                            data-target="#create"
                          >
                            Create FAQ
                          </button>
                        </div>
                      </div>
                      <div className="card-body">
                        <div id="accordion" className="accordion">
                          {faqs.map((faq, index) => (
                            <div className="accordion-item">
                              <a
                                href="#"
                                className="accordion-head"
                                data-toggle="collapse"
                                data-target={`#faq${index}`}
                              >
                                <h6 className="title">{faq.question}</h6>
                                <span className="accordion-icon" />
                              </a>
                              <div
                                className="accordion-body collapse show"
                                id={`faq${index}`}
                                data-parent="#accordion"
                              >
                                <div className="accordion-inner">
                                  <p>{faq.answer}</p>
                                  <div>
                                    <Link
                                      to="#"
                                      className="btn btn-sm btn-warning mr-1"
                                      title="Edit"
                                      onClick={() => setFaq(faq)}
                                      data-toggle="modal"
                                      data-target="#update"
                                    >
                                      <em className="icon ni ni-edit-fill"></em>
                                    </Link>
                                    <Link
                                      to="#"
                                      className="btn btn-sm btn-danger"
                                      title="Delete"
                                      onClick={() => {
                                        setFaq(faq);
                                      }}
                                      data-toggle="modal"
                                      data-target="#delete"
                                    >
                                      <em className="icon ni ni-trash-fill"></em>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="create"
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Create FAQ
              </h5>
              <button
                type="button"
                className="btn-danger"
                data-dismiss="modal"
                aria-label="Close"
              >
                {/* cross icon */}
                <em className="icon ni ni-cross" />
              </button>
            </div>
            <div className="modal-body">
              {message}
              <form onSubmit={createFaq}>
                <div className="form-group mb-3">
                  <label>Question</label>
                  <input
                    type="text"
                    className="form-control"
                    name="question"
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label>Answer</label>
                  <input
                    type="text"
                    className="form-control"
                    name="answer"
                    required
                  />
                </div>

                <div className="d-flex mt-2 justify-content-end ">
                  <button
                    type="button"
                    className="btn btn-light mr-3"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Create {loader}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        id="update"
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Update FAQ
              </h5>
              <button
                type="button"
                className="btn-danger"
                data-dismiss="modal"
                aria-label="Close"
              >
                <em className="icon ni ni-cross" />
              </button>
            </div>
            <div className="modal-body">
              {message}
              <form onSubmit={updateFaq}>
                <div className="form-group mb-3">
                  <label>Question</label>
                  <input
                    type="text"
                    className="form-control"
                    name="question"
                    value={faq.question}
                    onChange={(e) =>
                      setFaq({ ...faq, question: e.target.value })
                    }
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label>Answer</label>
                  <input
                    type="text"
                    className="form-control"
                    name="answer"
                    value={faq.answer}
                    onChange={(e) => setFaq({ ...faq, answer: e.target.value })}
                    required
                  />
                </div>

                <div className="d-flex mt-2 justify-content-end ">
                  <button
                    type="button"
                    className="btn btn-light mr-3"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Update {loader}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        id="delete"
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Delete FAQ
              </h5>
              <button
                type="button"
                className="btn-danger"
                data-dismiss="modal"
                aria-label="Close"
              >
                <em className="icon ni ni-cross" />
              </button>
            </div>
            <div className="modal-body">
              {message}
              <h6>Are you sure you want to delete this faq?</h6>
              <div className="d-flex mt-2 justify-content-end mt-3">
                <button
                  type="button"
                  className="btn btn-light mr-3"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={(e) => deleteFaq(e)}
                >
                  Delete {loader}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
