import React from "react";
import { useRoutes } from "react-router-dom";
import Dashboard from "../source/Dashboard";
import Orders from "../source/Orders";
import Ticket from "../source/Ticket";
import Users from "../source/Users";
import Settings from "../source/Settings";
import Pricing from "../source/Pricing";
import Ftid from "../source/Ftid";
import SingleType from "../source/Components/SingleType";
import Invoices from "../source/Invoices";
import CustomPricing from "../source/Components/CustomPricing";
import Website from "../source/Website";
import BulkOrders from "../source/BulkOrders";
import Faq from "../source/Faq";
import Refunds from "../source/Refunds";
const DashboardRoutes = (props) => {
  return useRoutes([
    { path: "", element: <Dashboard sidebarRef={props.sidebarRef} /> },
    { path: "/orders", element: <Orders sidebarRef={props.sidebarRef} /> },
    {
      path: "/tickets",
      element: <Ticket sidebarRef={props.sidebarRef} />,
    },

    {
      path: "/admin-settings",
      element: <Settings sidebarRef={props.sidebarRef} />,
    },
    {
      path: "/faq",
      element: <Faq sidebarRef={props.sidebarRef} />,
    },
    {
      path: "/users",
      element: <Users sidebarRef={props.sidebarRef} />,
    },
    {
      path: "/refunds",
      element: <Refunds sidebarRef={props.sidebarRef} />,
    },
    {
      path: "/users/:id",
      element: <CustomPricing sidebarRef={props.sidebarRef} />,
    },
    {
      path: "/bulk-orders",
      element: <BulkOrders sidebarRef={props.sidebarRef} />,
    },
    {
      path: "/invoices",
      element: <Invoices sidebarRef={props.sidebarRef} />,
    },
    {
      path: "/pricing",
      element: <Pricing sidebarRef={props.sidebarRef} />,
    },
    {
      path: "/pricing/:id",
      element: <SingleType sidebarRef={props.sidebarRef} />,
    },

    {
      path: "/ftid",
      element: <Ftid sidebarRef={props.sidebarRef} />,
    },
    {
      path: "/website",
      element: <Website sidebarRef={props.sidebarRef} />,
    },
  ]);
};

export default DashboardRoutes;
