import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import { server, config } from "../env";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Refunds = (props) => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    readRefunds();
  }, []);

  const readRefunds = async () => {
    await axios
      .get(server + "/api/v1/admin/refund/read", config)
      .then((res) => {
        setRequests(res.data.refunds);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // approve refund
  const approveRefund = async (id, status) => {
    const data = {
      status: status ? "refundAccepted" : "refundRejected",
    };

    await axios
      .put(server + "/api/v1/admin/refund/approve/" + id, data, config)
      .then((res) => {
        readRefunds();
        toast.success(res.data.message);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <div className="nk-wrap">
      <Header sidebarRef={props.sidebarRef} />
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      Refund Requests
                    </h3>
                  </div>
                </div>
              </div>
              <div className="nk-block">
                <div className="row ">
                  <div className="col-md-12">
                    <div className="card shadow-sm">
                      <div className="card-inner">
                        <div className="card-filter px-4">
                          <div className="d-md-flex justify-content-end align-items-center">
                            <div className="d-md-flex justify-content-center align-items-center">
                              {/* .search */}
                              <div className="col mt-3">
                                <div className="form-group mr-3 ">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search"
                                    // onChange={(e) => {
                                    //   getUsers(e.target.value);
                                    // }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>order</th>
                                  <th>Name</th>
                                  <th>Email</th>
                                  <th>Balance</th>
                                  <th>price</th>
                                  <th>status</th>
                                  <th>Refund Message</th>
                                  <th>Order Date</th>
                                  <th>Request Date</th>
                                  <th>Refund Rate</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {requests.map((request, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{request._id}</td>
                                    <td>{request.user?.username}</td>
                                    <td>{request.user?.email}</td>
                                    <td>{"$" + request.user?.balance}</td>
                                    <td>{"$" + request.price}</td>
                                    <td>
                                      {request.status === "refundRequested" ? (
                                        <span className="badge badge-warning">
                                          Refund Requested
                                        </span>
                                      ) : (
                                        <span className="badge badge-success">
                                          Refunded
                                        </span>
                                      )}
                                    </td>
                                    <td>{request.refundMessage}</td>
                                    <td>
                                      {new Date(
                                        request.createdAt
                                      ).toDateString()}
                                    </td>
                                    <td>
                                      {new Date(
                                        request.refundDate
                                      ).toDateString()}
                                    </td>
                                    <td>{request.refundPercentage + "%"}</td>
                                    <td>
                                      {/* accept or reject button */}
                                      {request.status === "refundRequested" && (
                                        <div className="d-flex justify-content-center">
                                          <button
                                            className="btn btn-success btn-sm mr-2"
                                            onClick={() => {
                                              approveRefund(request._id, true);
                                            }}
                                          >
                                            Accept
                                          </button>

                                          <button
                                            className="btn btn-danger btn-sm"
                                            onClick={() => {
                                              approveRefund(request._id, false);
                                            }}
                                          >
                                            Reject
                                          </button>
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div className="d-flex justify-content-end mt-5">
                            <nav>
                              <ul className="pagination">
                                <li
                                  className={
                                    page === 1
                                      ? "page-item disabled"
                                      : "page-item"
                                  }
                                >
                                  <Link
                                    className="page-link"
                                    to=""
                                    onClick={(e) => {
                                      setPage(page - 1);
                                    }}
                                  >
                                    Prev
                                  </Link>
                                </li>

                                <li
                                  className="page-item active"
                                  aria-current="page"
                                >
                                  <Link className="page-link" to="">
                                    {page}{" "}
                                    <span className="sr-only">(current)</span>
                                  </Link>
                                </li>

                                <li
                                  className={
                                    page === totalPages
                                      ? "page-item disabled"
                                      : "page-item"
                                  }
                                >
                                  <Link
                                    className="page-link"
                                    to=""
                                    tabIndex={-1}
                                    aria-disabled={
                                      page === totalPages ? true : false
                                    }
                                    onClick={(e) => {
                                      setPage(page + 1);
                                    }}
                                  >
                                    Next
                                  </Link>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Refunds;
