import React from "react";
import Header from "../components/Header";
import axios from "axios";
import Loader from "../components/Loader";
import { server, config } from "../env";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Website = (props) => {
  const [cashapp, setCashapp] = useState({});
  const [loader, setLoader] = useState("");

  const readCashapp = async () => {
    await axios
      .get(server + "/api/v1/invoice/read-cashapp", config)
      .then((res) => {
        setCashapp(res.data.credentials);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    readCashapp();
  }, []);

  const updateCashApp = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);

    const params = new FormData();
    params.append("cashappId", e.target.cashappId.value);
    params.append("image", e.target.qr.files[0]);

    await axios
      .put(server + "/api/v1/admin/update-cashapp", params, config)
      .then((res) => {
        setLoader("");
        toast.success(res.data.message);
        readCashapp();
      })
      .catch((err) => {
        setLoader("");
        toast.error(err.response.data.message);
      });
  };

  // const readCryptosList = async () => {
  //   await axios
  //     .get(server + "/api/v1/admin/crypto/read", config)
  //     .then((res) => {
  //       setCryptos(res.data.crypto);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // const createCryptoList = async (e) => {
  //   e.preventDefault();
  //   setLoader(<Loader />);
  //   const params = new FormData();
  //   params.append("name", e.target.name.value);
  //   params.append("address", e.target.address.value);
  //   params.append("ticker", e.target.ticker.value);
  //   params.append("confirmations", e.target.confirmations.value);
  //   params.append("color", e.target.color.value);
  //   params.append("image", e.target.logo.files[0]);
  //   await axios
  //     .post(server + "/api/v1/admin/crypto/create", params, config)
  //     .then((res) => {
  //       setLoader("");
  //       toast.success(res.data.message);
  //       readCryptosList();
  //     })
  //     .catch((err) => {
  //       setLoader("");
  //       toast.error(err.response.data.message);
  //     });
  // };

  // const deleteCrypto = async (id) => {
  //   setLoader(<Loader />);
  //   await axios
  //     .delete(server + "/api/v1/admin/crypto/delete/" + id, config)
  //     .then((res) => {
  //       setLoader("");
  //       toast.success(res.data.message);
  //       readCryptosList();
  //     })
  //     .catch((err) => {
  //       setLoader("");
  //       toast.error(err.response.data.message);
  //     });
  // };

  // const readAnnouncement = async () => {
  //   await axios
  //     .get(server + "/api/v1/announcement/read", config)
  //     .then((res) => {
  //       setAnnouncement(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // const updateAnnouncement = async (e) => {
  //   e.preventDefault();

  //   const params = {
  //     announcement: e.target.announcement.value,
  //     title: announcement.title,
  //     status: announcement.status,
  //   };

  //   await axios
  //     .put(server + "/api/v1/announcement/update", params, config)
  //     .then((res) => {
  //       toast.success(res.data.message);
  //       readAnnouncement();
  //     })
  //     .catch((err) => {
  //       toast.error(err.response.data.message);
  //     });
  // };

  return (
    <div className="nk-wrap">
      {/* main header @s */}
      <Header sidebarRef={props.sidebarRef} />
      {/* main header @e */}
      {/* content @s */}
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      Website Settings
                    </h3>
                  </div>
                </div>
              </div>
              <div className="nk-block">
                {/* <div className="row mb-3">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">Announcement</h4>
                      </div>
                      <div className="card-body">
                        <form onSubmit={updateAnnouncement}>
                          <div className="form-group mb-3">
                            <label>Title</label>
                            <input
                              className="form-control"
                              name="title"
                              value={announcement.title}
                              onChange={(e) =>
                                setAnnouncement({
                                  ...announcement,
                                  title: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="form-group mb-3">
                            <label>Announcement</label>
                            <textarea
                              className="form-control"
                              rows="5"
                              defaultValue={announcement.announcement}
                              name="announcement"
                            />
                          </div>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              name="status"
                              id="flexSwitchCheckDefault"
                              checked={announcement.status}
                              onChange={() => {
                                setAnnouncement({
                                  ...announcement,
                                  status: !announcement.status,
                                });
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexSwitchCheckDefault"
                            >
                              Enable
                            </label>
                          </div>

                          <div className="mt-3 text-end">
                            <button className="btn btn-primary submit-btn">
                              Save Changes
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div> */}

                <div className="row">
                  <div className="col-lg-4">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="card-title">Cashapp</h5>
                      </div>

                      <div className="card-body">
                        <form onSubmit={updateCashApp}>
                          <div className="form-group">
                            <label htmlFor="oldPassword">Cashapp ID</label>
                            <input
                              type="text"
                              className="form-control"
                              id="cashappId"
                              placeholder="Enter cashapp ID"
                              name="cashappId"
                              value={cashapp.cashappId}
                              onChange={(e) =>
                                setCashapp({
                                  ...cashapp,
                                  cashappId: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="newPassword">QR code</label>
                            <input
                              type="file"
                              className="form-control"
                              id="qr"
                              name="qr"
                            />
                          </div>

                          {/* show image */}
                          <div className="form-group">
                            <img
                              src={server + "/" + cashapp.qr}
                              alt="cashapp"
                              className="img-fluid"
                            />
                          </div>

                          <button type="submit" className="btn btn-primary">
                            Update Cashapp Credentials {loader}
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Website;
