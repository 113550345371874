import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import axios from "axios";
import { server, config } from "../env";
import Loader from "../components/Loader";
import Alert from "../components/Alert";
import { Link } from "react-router-dom";

const Ticket = (props) => {
  const [orders, setOrders] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [alert, setAlert] = useState("");
  const [loader, setLoader] = useState("");
  const [filter, setFilter] = useState("All");
  const [sort, setSort] = useState("asc");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState();
  const [total, setTotal] = useState(0);
  const [ticket, setTicket] = useState({});
  const [updateLoader, setUpdateLoader] = useState("");
  const [updateMessage, setUpdateMessage] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);

  const getTickets = async () => {
    await axios
      .get(
        `${server}/api/v1/admin/tickets/read?status=${filter}&sort=${sort}&page=${page}&limit=${limit}`,
        config
      )
      .then((res) => {
        setTickets(res.data.tickets);
        setTotalPages(res.data.totalPages);
        setTotal(res.data.total);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getTickets();
  }, [page, limit, filter, sort]);

  const updateTicket = async (e) => {
    e.preventDefault();
    setUpdateLoader(<Loader />);

    const data = {
      status: e.target.status.value,
    };

    await axios
      .put(server + "/api/v1/admin/tickets/update/" + ticket._id, data, config)
      .then((res) => {
        setUpdateLoader("");
        setUpdateMessage(
          <Alert className="success" message="Ticket updated successfully" />
        );
        getTickets();
      })
      .catch((err) => {
        console.log(err);
        setUpdateLoader("");
        setUpdateMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
      });

    setTimeout(() => {
      setUpdateMessage("");
      e.target.reset();
    }, 3000);
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    setUpdateLoader(<Loader />);

    setIsDisabled(true);

    const data = {
      ticketId: ticket._id,
      message: e.target.message.value,
    };

    await axios
      .put(server + "/api/v1/admin/ticket/updateMessage", data, config)
      .then((res) => {
        setUpdateLoader("");
        setUpdateMessage(
          <Alert className="success" message="Message sent successfully" />
        );
        getTickets();
        setTicket(res.data.ticket);
      })
      .catch((err) => {
        console.log(err);
        setUpdateLoader("");
        setUpdateMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
      });

    setTimeout(() => {
      setUpdateMessage("");
      e.target.reset();
      setIsDisabled(false);
    }, 3000);
  };

  return (
    <div className="nk-wrap">
      {/* main header @s */}
      <Header sidebarRef={props.sidebarRef} />
      {/* main header @e */}
      {/* content @s */}
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">Tickets</h3>
                  </div>
                </div>
                <div className="nk-block">
                  <div className="row">
                    <div className="col-xxl-12 col-sm-12">
                      <div className="card h-100 mt-5">
                        <div className="card-header">
                          <div className="card-title">
                            <h5 className="card-title text-center">
                              <span className="text-primary text-center">
                                <em className="icon ni ni-ticket" />
                              </span>
                              <span className="text-center">Tickets</span>
                            </h5>
                          </div>

                          {/* search and filter dropdown */}
                        </div>
                        <div className=" d-flex justify-content-end card-filter mt-3 mr-5">
                          <div className="d-flex justify-content-between">
                            <div className="d-flex justify-content-center ">
                              <button
                                className="btn btn-light dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <em className="icon ni ni-filter" />
                                <span>{filter}</span>
                              </button>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <Link
                                  to=""
                                  className="dropdown-item"
                                  onClick={(e) => {
                                    setFilter("All");
                                  }}
                                >
                                  All
                                </Link>
                                <Link
                                  className="dropdown-item"
                                  to=""
                                  onClick={(e) => {
                                    setFilter("Open");
                                  }}
                                >
                                  Open
                                </Link>
                                <Link
                                  className="dropdown-item"
                                  to=""
                                  onClick={(e) => {
                                    setFilter("Closed");
                                  }}
                                >
                                  Closed
                                </Link>
                                <Link
                                  className="dropdown-item"
                                  to=""
                                  onClick={(e) => {
                                    setFilter("Waiting for Customer Response");
                                  }}
                                >
                                  Waiting for Customer Response
                                </Link>
                              </div>

                              <div className="form-group ml-2">
                                <select
                                  name="sort"
                                  id=""
                                  className="form-control"
                                  value={sort}
                                  onChange={(e) => {
                                    setSort(e.target.value);
                                  }}
                                >
                                  <option value="asc">
                                    Sort by Date Ascending
                                  </option>
                                  <option value="des">
                                    Sort by Date Descending
                                  </option>
                                  <option value="asc1">
                                    Sort by Last Message Ascending
                                  </option>
                                  <option value="des1">
                                    Sort by Last Message Descending
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th>Order</th>
                                  <th>Subject</th>
                                  <th>Status</th>
                                  <th>Last Response</th>
                                  <th>Created At</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {tickets.map((ticket, index) => (
                                  <tr key={index}>
                                    <td>
                                      <Link to="">
                                        {ticket.order_uuid || "N/A"}
                                      </Link>
                                    </td>
                                    <td>{ticket.subject}</td>
                                    <td>
                                      {ticket.status === "open" ? (
                                        <span className="badge badge-success">
                                          Open
                                        </span>
                                      ) : ticket.status === "closed" ? (
                                        <span className="badge badge-danger">
                                          Closed
                                        </span>
                                      ) : (
                                        <span className="badge badge-warning">
                                          Waiting for Customer Response
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {ticket.lastMessage
                                        ? new Date(
                                            ticket.lastMessage
                                          ).toLocaleString()
                                        : "N/A"}
                                    </td>
                                    <td>
                                      {new Date(
                                        ticket.createdAt
                                      ).toDateString()}
                                    </td>
                                    <td>
                                      <button
                                        className="btn btn-primary mr-2"
                                        data-toggle="modal"
                                        data-target="#ticket"
                                        onClick={() => {
                                          setTicket(ticket);
                                        }}
                                      >
                                        Update Status
                                      </button>
                                      <button
                                        className="btn btn-info"
                                        data-toggle="modal"
                                        data-target="#message"
                                        onClick={() => {
                                          setTicket(ticket);
                                        }}
                                      >
                                        Reply
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div className="d-flex justify-content-end mt-3">
                            <nav>
                              <ul className="pagination">
                                <li
                                  className={
                                    page === 1
                                      ? "page-item disabled"
                                      : "page-item"
                                  }
                                >
                                  <Link
                                    className="page-link"
                                    to=""
                                    onClick={(e) => {
                                      setPage(page - 1);
                                    }}
                                  >
                                    Prev
                                  </Link>
                                </li>

                                <li
                                  className="page-item active"
                                  aria-current="page"
                                >
                                  <Link className="page-link" to="">
                                    {page}{" "}
                                    <span className="sr-only">(current)</span>
                                  </Link>
                                </li>

                                <li
                                  className={
                                    page === totalPages
                                      ? "page-item disabled"
                                      : "page-item"
                                  }
                                >
                                  <Link
                                    className="page-link"
                                    to=""
                                    tabIndex={-1}
                                    onClick={(e) => {
                                      setPage(page + 1);
                                    }}
                                  >
                                    Next
                                  </Link>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" tabIndex={-1} id="ticket">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Update Ticket</h5>
              <a
                href="#"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <em className="icon ni ni-cross" />
              </a>
            </div>
            {updateMessage}
            <div className="modal-body">
              <form onSubmit={updateTicket} className="form-validate is-alter">
                <div className="form-group">
                  <label className="form-label" htmlFor="full-name">
                    Status
                  </label>

                  <select
                    name="status"
                    id=""
                    className="form-control"
                    value={ticket.status}
                    onChange={(e) => {
                      setTicket({ ...ticket, status: e.target.value });
                    }}
                  >
                    <option value="waiting for customer response">
                      Waiting for Customer Response
                    </option>
                    <option value="open">Open</option>
                    <option value="closed">Closed</option>
                  </select>
                </div>

                <div className="form-group">
                  <button type="submit" className="btn btn-lg btn-primary">
                    Update {updateLoader}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" tabIndex={-1} id="message">
        <div className="modal-dialog modal-dialog-scrollable" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Support</h5>
              <a
                href="#"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <em className="icon ni ni-cross" />
              </a>
            </div>
            {updateMessage}
            <div className="modal-body">
              <form onSubmit={sendMessage} className="form-validate is-alter">
                {/* show messages with timestamp and seperate admin and user messages */}

                <div className="nk-chat-panel" data-simplebar="">
                  {ticket.messages?.map((message, index) => (
                    <div
                      className={
                        message.username === "admin"
                          ? "chat is-me"
                          : "chat is-you"
                      }
                    >
                      <div className="chat-content">
                        <div className="chat-bubbles">
                          <div className="chat-bubble">
                            <div className="chat-msg"> {message.message} </div>
                          </div>
                        </div>
                        <ul className="chat-meta">
                          <li>{message.username}</li>
                          <li>
                            {new Date(message.timestamp).toLocaleDateString()}
                          </li>
                        </ul>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="form-group mt-3">
                  <textarea
                    name="message"
                    id=""
                    className="form-control"
                    placeholder="Type your message here"
                  ></textarea>
                </div>

                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-lg btn-primary"
                    disabled={isDisabled}
                  >
                    Send {updateLoader}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ticket;
